import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

import Layout from "../components/layout"
import SEO from "../components/seo"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data })
    })
      .then(() => {
        setSubmitted(true)
      }, error => {
        setSubmissionError(error)
      });
  };

  return (
    <Layout>
      <SEO title="Contact Erik" description={'Use this simple form to provide your name, email and a message describing your project or opportunity for Erik.'} keywords={'Erik Altman, contact'} />
      <div className="shadow">
        <div className="container px-5 py-5 text-light">
          <h1 className="display-4">Contact Erik</h1>
        </div>
      </div>
      <div className="bg-light">
        {
          submissionError &&
          <p>
            Sorry there was an error submitting this form!  Try again soon!
          </p>
        }
        {
          submitted ? <div className="container px-5 py-5 text-center">
            <h2>Thanks for reaching out!</h2>
            <p>Erik looks forward to getting back to you as soon as he can.</p>
          </div>
            : <form
              className="container pt-4 pb-5 d-flex flex-column align-items-center"
              method="POST"
              name="contact"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input type="hidden" name="bot-field" />
              <div className="bd-callout w-75 bg-white">
                Provide your name, email and a message describing your project or opportunity for Erik.
            </div>
              <div className="form-group w-75">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              {errors.name && <div className="alert alert-danger w-75">
                Name is required
                </div>}
              <div className="form-group w-75">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  ref={register({ required: true })}
                />
              </div>
              {errors.email && <div className="alert alert-danger w-75">
                Email is required
                </div>}
              <div className="form-group w-75">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Message"
                  rows="7"
                  name="message"
                  ref={register({ required: true })}
                ></textarea>
              </div>
              {errors.message && <div className="alert alert-danger w-75">
                Message is required
                </div>}
              <button type="submit" className="btn btn-primary w-75">Submit</button>
            </form>
          }
        </div>
    </Layout>
  )
}

